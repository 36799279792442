<template>
    <div id="Awards">
        <b-container>
            <h2>Награды и благодарности команды</h2>
            <b-row>
                <b-col cols="2">
                    <img src="../../public/awards/awards.png" alt="Награды">
                </b-col>
                <b-col>
                    <p class="awards">Выражаем благодарность команде гримеров <span>#polyakovsteam</span> за профессиональную работу, вклад в организацию турниров и развитие National Bodybuilding Community.</p>
                    <br>
                    <p class="author">Федерация National Bodybuilding Community.</p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "Awards",
    }
</script>

<style scoped lang="scss">

</style>