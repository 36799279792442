<template>
    <div id="Preparation">
        <b-container>
            <b-row>
                <b-col cols="4">
                    <p><span>П</span>од яркими софитами грим выделяет всю мускулатуру<span>,</span> подчеркивает мельчайшие мышцы спортсмена<span>.</span></p>
                    <p><span>Б</span>лагодаря гриму спортсмен демонстрирует судьям всю красоту своего тела<span>.</span></p>
                    <br/>
                    <p><span>С</span>портсмену необходимо подготовить кожу заранее<span>.</span></p>
                    <p><span>П</span>ри правильной подготовке краска ляжет равномерно и будет замечательно смотреться после высыхания<span>.</span></p>
                    <p><span>Ч</span>тобы грим лег ровно и красиво необходимо соблюдать ряд простых правил<span>.</span></p>
                </b-col>
                <b-col cols="4">
                    <img src="../../public/componentsImg/preparation.png" alt="Подготовка">
                </b-col>
                <b-col>
                    <h2>Этапы подготовки кожи к гриму</h2>
                    <ol>
                        <li v-for="(preparation,index) in Preparation" :key="index">{{preparation}}</li>
                    </ol>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "Preparation",
        data(){
            return{
                Preparation:[
                    'За 1-2 дня до соревнований следует удалить волосы со всего тела: от шеи до пяток.',
                    'Необходимо отскрабировать кожу, желательно несколько процедур в течение недели.',
                    'За 1 сутки до соревнований надо перестать пользоваться любыми маслянистыми средствами.',
                    'Утром в день соревнований нужно принять душ с обычным мылом и обязательно хорошо смыть его с кожи.'
                ],
            }
        }
    }
</script>

<style scoped lang="scss">

</style>