<template>
  <svg viewBox="0 0 473.65 473.65" xmlns="http://www.w3.org/2000/svg">
    <path d="m363.64 292.42c-18.324-17.625-36.876-28.712-59.081-12.285-22.336 16.529-24.7 34.875-24.7 34.875s2.098 35.167-82.671-41.068c-76.287-84.806-41.098-82.708-41.098-82.708s18.339-2.363 34.868-24.7c16.428-22.202 4.835-40.275-12.284-59.085-54.777-60.185-87.981 8.208-87.981 8.208-38.783 65.274 25.863 142.85 75.734 189.06 46.225 49.871 123.76 114.46 189.01 75.681-4e-3 2e-3 69.249-29.246 8.204-87.983z" class="svg_color"/>
    <path d="m121.04 85.419c-0.045 0.015-0.067 0.052-0.108 0.071 0.49-0.206 0.995-0.389 1.488-0.572-0.46 0.165-0.927 0.318-1.38 0.501z" class="svg_color"/>
  </svg>
</template>

<script>
    export default {
        name: "telephone_svg"
    }
</script>

<style scoped>

</style>
